<template>
  <div class=" ">
    <div class="section">
      <!-- 
    <dashTabs />
     -->
      GPTS ADMIN 34252398
      <botsTabs v-if="$store.main.getters.hasPages" v-show="false" />
      <div class="container" v-if="!loading && bots.length">
        <br />
        <div class=" " v-if="bots.length">
          <h1 class="title is-3">Created by you</h1>
          <h1 class="subtitle is-5">Create content, manage and monetize your AI creations</h1>

          <router-link to="/bots/create">
            <b-button rounded> <i class="fal fa-plus" style=""></i> <span> </span>New bot </b-button>
          </router-link>

          <!--   <router-link to="/bots/create" v-if="bots.length > 1">
        fa-users-class only monetizable bot! 
            <b-button rounded> <i class="fal fa-ball-pile" style=""></i> <span> </span>Create a Bundle </b-button>
          </router-link>-->
        </div>

        <div v-else>
          <h1 class="title is-2">Creating pages</h1>
          <h1 class="subtitle is-4">Design a chat AI, publish AI images, or monetize your AI creations.</h1>
          <p>We'll guide you over the steps to publish your own AI engines easily.</p>
          <br />
          <router-link to="/bots/create">
            <b-button rounded type="is-dark">
              <i class="fal fa-plus" style=""></i> <span> </span>Let's do this
            </b-button>
          </router-link>
        </div>
        <br />
        <BotList :bots="bots" prefix="/bots/me" layout="cardManage" :noDescription="true" :pageSize="createdNbShown" />

        <br />

        <b-button
          outlined
          rounded
          type="is-dark"
          @click="createdNbShown = 999"
          v-if="createdNbShown < 888 && createdNbShown < bots.length"
        >
          <!-- 
        <i class="fal fa-plus" style=""></i> 
         -->
          <span> View more</span>
        </b-button>
        <!-- 
      <BotList :bots="bots" prefix="/bots/me" :noDescription="true" :pageSize="999" /> -->

        <div class=" " v-if="bots.length && false">
          <b-table :data="bots" class="botTable">
            <template>
              <!-- 
 

           -->
              <b-table-column field="avatar" width="5" label="Avatar" v-slot="props">
                <router-link :to="{ name: 'bot', params: { bot: props.row.handle } }">
                  <img
                    :src="props.row.avatar"
                    alt=" "
                    style="background: #fff; width: 80px; height: 80px; border-radius: 200px; object-fit: cover"
                  />
                </router-link>
              </b-table-column>
              <b-table-column field="handle" width="8" label="Name" v-slot="props">
                <span>
                  <router-link :to="props.row.url">
                    <strong> {{ props.row.handle }} </strong>
                    <!-- 
                  <i class="fal fa-lightbulb-slash" v-if="props.row.handle" style="color: #aaa"></i>
                 --></router-link
                  >
                  <br />
                  <div class="description">{{ truncate(props.row.bio, 30) }}</div>
                </span>
              </b-table-column>
              <!--
          <b-table-column field="description" label="Description" v-slot="props">
            <div class="description">{{ truncate(props.row.bio, 100) }}</div>
          </b-table-column>  -->

              <b-table-column field="nbPosts" width="2" label="Posts" v-slot="props">
                <span> {{ props.row.nbPosts || 0 }} <i class="fal fa-tag" style="color: #aaa"></i></span>
              </b-table-column>

              <b-table-column field="nbViews" width="2" label="Views" v-slot="props">
                <span>
                  {{ props.row.nbViews || 0 }}
                  <i class="fal fa-eye" style="color: #aaa"></i
                ></span>
              </b-table-column>

              <b-table-column field="nbFollowers" label="Followers" width="2" v-slot="props">
                <span> {{ props.row.nbFollowers || 0 }} <i class="fal fa-users" style="color: #aaa"></i></span>
              </b-table-column>

              <b-table-column field="nbConvos" width="2" label="Chats" v-slot="props">
                <span>
                  {{ props.row.nbConvos || 0 }}
                  <i class="fal fa-comments" style="color: #aaa"></i
                ></span>
              </b-table-column>

              <b-table-column v-slot="props" width="30">
                <router-link :to="`/${props.row.handle}/posts/new`">
                  <b-button rounded class="button is-dark">
                    <i class="fal fa-plus" style=""></i> <span> Post</span>
                  </b-button>
                </router-link>
                <router-link :to="{ name: 'BotSettings', params: { bot: props.row.handle } }">
                  <b-button rounded> <i class="fal fa-cogs" style=""></i> <span> Configure</span> </b-button>
                </router-link>
                <!-- 
                   <b-button class="button is-infoNO" icon="cog" icon-pack="fas">Configure</b-button>
            <button class="button is-primary" @click="viewBot(props.row)">View</button>
            <button class="button is-info" @click="manageBot(props.row)">Manage</button>
             -->
              </b-table-column>
            </template>
          </b-table>
        </div>
        <br />
        <br />
      </div>
    </div>
    <FeaturedBots />
  </div>
</template>

<script>
import dashTabs from "@/components/nav/dashTabs.vue";
import BotList from "@/components/BotList.vue";
import FeaturedBots from "@/components/FeaturedBots.vue";

import botsTabs from "@/components/nav/botsTabs.vue";
export default {
  data() {
    return {
      bots: [],
      loading: true,
      createdNbShown: 6,
    };
  },
  components: {
    //  dashTabs,
    BotList,
    FeaturedBots,
    botsTabs,
  },
  methods: {
    async fetchBots() {
      try {
        //   const response = await fetch("/api/bots");
        const data = await window.API.getAllGpts();
        this.loading = false;
        this.bots = data;
      } catch (error) {
        console.error(error);
      }
    },
    truncate(text2, length) {
      if (!text2) return;
      var text = String(text2);
      if (text.length > length) {
        return String(text).substring(0, length) + "...";
      }
      return text;
    },
    viewBot(bot) {
      // Code to view bot details
    },
    manageBot(bot) {
      // Code to manage bot settings
    },
  },
  mounted() {
    this.fetchBots();
  },
};
</script>

<style>
.botTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botTable td {
  vertical-align: middle;
}
</style>
